import { Field, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import MultiSelectField from "../../components/common/MultiSelectField";
import * as Yup from "yup";
import { sendRequestGetPublishers } from "../../view_logics/Publishers";
import AppStore from "../../config/AppStore";
import LoadingButton from "../../components/common/LoadingButton";
import { useParams } from "react-router-dom";

let phoneMeta = [
  { id: "cell", label: "Cell" },
  { id: "landline", label: "Landline" },
];

const PublishersTab = (advertiserOfferInfo, setActiveTab) => {
  const [targetingFormLoading, setTargetingFormLoading] = useState(false);
  let store = AppStore.getAppStoreInstance();
  const [publishersApiData, setPublishersApiData] = useState([]);
  const [publishersList, setPublishersList] = useState([]);
  const { id } = useParams();

  const getPublishersInputObj = {
    accessToken: store.getState().login.accessToken,
    url: "",
    dataHander: setPublishersApiData,
  };

  const formik = useFormik({
    initialValues: {
      targetDeviceFlag: false,
    },
    onSubmit: (values) => {
      // Handle form submission
      // ...
    },
  });

  let publishersInfoObj = {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sendRequestGetPublishers(getPublishersInputObj);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (publishersApiData?.length > 0) {
      let tempArr = [];
      tempArr = publishersApiData?.map((obj, ind) => {
        let updatedObj = {
          id: obj.id,
          label: obj.first_name + " " + obj?.last_name,
        };
        return updatedObj;
      });
      if (tempArr?.length > 0) {
        setPublishersList(tempArr);
      }
    }
  }, [publishersApiData]);

  const publisherValidation = Yup.object().shape({
    targetToAge: Yup.number()
      .integer("Please enter a valid integer for to age")
      .min(
        Yup.ref("targetFromAge"),
        "To age must be greater than or equal to from age"
      )
      .required("To age is required"),
  });

  const updatePublishersInfo = () => {};
  return (
    <div className="position-relative">
      <div className="tab-pane active" id="tab-1" role="tabpanel">
        <Formik
          initialValues={publishersInfoObj || formik.initialValues}
          validationSchema={publisherValidation}
          // onSubmit={updateTagetingInfo}
          enableReinitialize
        >
          {({ handleSubmit, errors, isValid, values }) => {
            console.log("values of targeting", values);
            return (
              <form onSubmit={updatePublishersInfo}>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Select Filters</h5>
                    {!advertiserOfferInfo.id ? (
                      <div
                        className="alert alert-danger alert-outline alert-dismissible"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                        <div className="alert-icon">
                          <i className="far fa-fw fa-bell"></i>
                        </div>
                        <div className="alert-message">
                          <strong>Warning!</strong> Please fill out and save the
                          basic information first!
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Select Publishers</label>

                        <MultiSelectField
                          name="targetingPhone"
                          options={publishersList}
                          isMulti={true}
                          //   disabled={!values?.targetPhoneFlag}
                        />
                      </div>
                    </div>
                  </div>


                  <hr></hr>
                  <div className="form-group row mb-0">
                    <div className="d-flex justify-content-end pe-4 pb-3">
                      {targetingFormLoading ? (
                        <LoadingButton name="Save" />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={id && !advertiserOfferInfo.id}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default PublishersTab;
