// import React from 'react';



class Constants {
  constructor() {
    if (this.constructor === Constants) {
      throw new TypeError(
        'Abstract class "Constants" cannot be instantiated directly.'
      );
    }
  }
  static AppStoreState = {
    userId: null,
    currentExecutedAction: null,
    userToken: null,
    isStaff: null,
  };
  static get APP_SESSION_KEEP_ALIVE_INTERVAL() {
    return 9000;
  }
  static get APP_SERVER_HOST_NAME() {
    return "env1.xoptin.com";
  }
  static get APP_SERVER_PORT() {
    return 8000;
  }
  static get APP_SERVER_SCHEME() {
    return "https://";
  }
  static get APP_SERVER_BASE_URL() {
    return (
      Constants.APP_SERVER_SCHEME +
      Constants.APP_SERVER_HOST_NAME +
      // ":" +
      // Constants.APP_SERVER_PORT +
      "/"
    );
  }
  static get APP_SERVER_AUTHENTICATE_USER_URL() {
    return Constants.APP_SERVER_BASE_URL + "jwt/";
  }
  static get APP_SERVER_VALIDATE_USER_TOKEN_URL() {
    return Constants.APP_SERVER_BASE_URL + "jwt/";
  }
  static get APP_SERVER_OPTION_ADVERTISERS_LIST_URL() {
    return Constants.APP_SERVER_BASE_URL + "advertisers/";
  }
  

  static get APP_SERVER_CUSTOMER_DAILY_PROFIT_REPORT() {
    return Constants.APP_SERVER_BASE_URL + "customer/daily_profit_report/";
  }
  static get APP_SERVER_STAFF_DAILY_PROFIT_REPORT() {
    return Constants.APP_SERVER_BASE_URL + "staff/reports/daily/performance/";
  }

  static get APP_SERVER_GET_PROFIT_LOSS_DISTRIPUTION() {
    return Constants.APP_SERVER_BASE_URL + "staff/profit_loss_distribution/";
  }

  static get APP_SERVER_GET_PROFIT_LOSS_DETAIL() {
    return Constants.APP_SERVER_BASE_URL + "staff/profit_loss_detail/";
  }

  static get APP_SERVER_GET_NEWSLETTERS() {
    return Constants.APP_SERVER_BASE_URL + "newsletter";
  }
  static get APP_SERVER_CREATE_NEWSLETTERS() {
    return Constants.APP_SERVER_BASE_URL + "create_newsletter/";
  }
  static get APP_SERVER_IMPORT_TICKER_DATA() {
    return Constants.APP_SERVER_BASE_URL + "import_ticker_data/";
  }
  static get APP_SERVER_OPTION_CHART_DATA() {
    return Constants.APP_SERVER_BASE_URL + "option_chart_data/";
  }
  static get APP_SERVER_TICKER_PRICE_ACTION() {
    return Constants.APP_SERVER_BASE_URL + "ticker_price_action_chart/";
  }
  static get APP_SERVER_TICKER() {
    return Constants.APP_SERVER_BASE_URL + "ticker/";
  }
  static get APP_SERVER_STRATEGY() {
    return Constants.APP_SERVER_BASE_URL + "strategy/";
  }
  static get APP_SERVER_STRATEGY_TICKER() {
    return Constants.APP_SERVER_BASE_URL + "strategy_ticker/";
  }
  static get APP_SERVER_STRATEGY_PERFORMANCE() {
    return Constants.APP_SERVER_BASE_URL + "strategy_performance/";
  }
  static get APP_SERVER_TICKER_SUPPORT_RESISTANCE() {
    return Constants.APP_SERVER_BASE_URL + "support_resistance/";
  }
  static get APP_SERVER_TICKER_BREAKOUT_CONFIRMATION() {
    return Constants.APP_SERVER_BASE_URL + "ticker_breakout_confirmation/";
  }

  static get APP_URI_LOGIN() {
    return "/login";
  }
  static get APP_URI_DASHBOARD() {
    return "/";
  }

}


export default Constants;

