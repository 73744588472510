import React, { useEffect, useState } from "react";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import { sendCreateAdvertiserFormSubmitRequest } from "../../view_logics/CreateAdvertiser";
import { useNavigate, useParams } from "react-router-dom";
import AppStore from "../../config/AppStore";
import Constants from "../../config/Constants";
import { sendPOSTRequest } from "../../utils/common_functions";
import {
  sendRequestToCreateWebsite,
  sendRequestToFetchWebsiteDetails,
  sendRequestToUpdateWebsiteDetail,
} from "../../view_logics/Websites/Website";
import * as Yup from "yup";
import { sendRequestGetPublishers } from "../../view_logics/Publishers";
import Spinner from "../../components/common/Spinner";
import { useSelector } from "react-redux";
import LoadingButton from "../../components/common/LoadingButton";

const CreateWebsite = () => {
  const [websiteFormLoading, setWebsiteFormLoading] = useState(false);

  const { userType } = useSelector((state) => state?.login);
  console.log("🚀 ~ CreateWebsite ~ userType:", userType);
  let store = AppStore.getAppStoreInstance();
  let accessToken = store.getState().login.accessToken;
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const formik = useFormik({
    initialValues: {
      name: "",
      domain: "",
      publisherId: "publisher",
    },
    onSubmit: async (values, { setSubmitting }, event) => {
      console.log("here");
      event.preventDefault();
    },
  });

  const [publishers, setPublishers] = useState([]);

  const inputObj = {
    accessToken: accessToken,
    url: "editPublisher",
    history: navigate,
    data: [],
    dataHander: setPublishers,
  };

  const { id } = useParams();
  const [websiteById, setWebsiteById] = useState(null);

 
  useEffect(() => {
    if (publishers?.length == 0) {
      const fetchPublishers = async () => {
        try {
          let res = await sendRequestGetPublishers(inputObj);
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };
      fetchPublishers();
    }

    if (id != undefined) {
      const fetchData = async () => {
        let res = await sendRequestToFetchWebsiteDetails({
          id: id,
          accessToken: accessToken,
        });
        if (res) {
          setWebsiteById({ ...res, publisherId: res?.publisher_id });
        }
      };
      fetchData();
    }
  }, [id, publishers]);

  const saveAdvertiser = (event) => {
    event.preventDefault();
    console.log("saveAdvertiser");
    // console.log(event.target.name.value);
    const formData = new FormData(event.target);
    const serializedData = {};

    for (let [name, value] of formData.entries()) {
      if (name.includes("Billing")) {
        if (!serializedData.hasOwnProperty("BillingSettings")) {
          serializedData["BillingSettings"] = {}; // Create the 'billing' object if it doesn't exist
        }
        serializedData["BillingSettings"][name] = value;
      } else if (name.includes("Lead")) {
        if (!serializedData.hasOwnProperty("LeadSettings")) {
          serializedData["LeadSettings"] = {}; // Create the 'billing' object if it doesn't exist
        }
        serializedData["LeadSettings"][name] = value;
      } else if (name.includes("Ping")) {
        if (!serializedData.hasOwnProperty("PingSettings")) {
          serializedData["PingSettings"] = {}; // Create the 'billing' object if it doesn't exist
        }
        serializedData["PingSettings"][name] = value;
      } else if (name == "publisherId") {
        serializedData["publisher_id"] = value;
      } else {
        serializedData[name] = value;
      }
    }
    if (id) {
      serializedData["website_id"] = id;
    }
    
    if ( !serializedData['publisher_id'] ){
      serializedData['publisher_id'] = store.getState().login.publisherId;
    }
    
    const jsonData = JSON.stringify(serializedData);
    console.log("jsondara", jsonData);
    const inputObj = {
      accessToken: accessToken,
      url: "/websites",
      history: navigate,
      dataToPost: serializedData,
      loadingHandler: setWebsiteFormLoading,
    };
    setWebsiteFormLoading(true);

    if (id != undefined) {
      sendRequestToUpdateWebsiteDetail(inputObj);
    } else {
      sendRequestToCreateWebsite(inputObj);
    }
  };

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const { errors } = formik;

  return (
    <div className="position-relative">
      <main className="content">
        <div className="container-fluid p-0">
          <h1>{id ? "Edit Website" : "Create New Website"}</h1>
          <Formik
            initialValues={websiteById || formik.initialValues}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is Required"),
              domain: Yup.string()
                .matches(
                  /^(?!:\/\/)([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.)+[a-zA-Z]{2,}$/,
                  "Invalid domain format. Example: domain.com"
                )
                .required("Domain is required")
                .strict(true),
              publisherId: Yup.string().required("Publisher Id Required"),
            })}
            enableReinitialize
          >
            {({ handleSubmit, errors, isValid }) => {
              console.log(
                "🚀 ~ file: CreateWebsite.js:166 ~ CreateWebsite ~ errors:",
                errors
              );
              return (
                <form onSubmit={saveAdvertiser}>
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Basic Info</h5>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Name">
                            Short Name
                          </label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            required
                            //   value={websiteById && websiteById.name}
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label className="form-label" htmlFor="Email">
                            Domain
                          </label>
                          <Field
                            type="text"
                            id="domain"
                            name="domain"
                            className={"form-control"}
                            required
                          />
                          <ErrorMessage
                            name="domain"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>  
                        {userType == "admin" && (
                          <div className="row">
                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="advertiserId"
                              >
                                Publisher ID
                              </label>
                              <Field
                                as="select"
                                id="publisherId"
                                name="publisherId"
                                className="form-control"
                                // value={websiteById?.publisher_id || ''}
                                required
                              >
                                <option value="">
                                  Please select publisher
                                </option>
                                {publishers
                                  ? publishers.map((publisher) => (
                                      <option
                                        key={publisher.id}
                                        value={publisher.id}
                                      >
                                        {publisher?.first_name +
                                          " " +
                                          publisher?.last_name}
                                      </option>
                                    ))
                                  : ""}
                              </Field>
                              <ErrorMessage
                                name="publisherId"
                                component="div"
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              />
                            </div>


                            <div className="mb-3 col-md-6">
                              <label
                                className="form-label"
                                htmlFor="advertiserId"
                              >
                               Category
                              </label>
                              <Field
                                as="select"
                                id="risk_type"
                                name="risk_type"
                                className="form-control"
                                required
                              >
                                <option value="">
                                  Please select category
                                </option>
                                {
                                Object.keys(Constants.WEBSITE_RISK_TYPES).map(key =>
                                <option value={key}>{Constants.WEBSITE_RISK_TYPES[key]}</option>)
                                }
                              </Field>
                              <ErrorMessage
                                name="risk_type"
                                component="div"
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              />
                            </div>


                          </div>
                        )}
                      
                    </div>
                  </div>

                  <hr></hr>
                  <div className="form-group row mb-0">
                    <div className="d-flex justify-content-end">
                      {websiteFormLoading ? (
                        <LoadingButton name="Save" />
                      ) : (
                        <button
                          disabled={isValid ? false : true}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </main>
      {id && !websiteById && <Spinner />}
    </div>
  );
};

export default CreateWebsite;
