import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";
import { sendUpdateAdvertiserOfferTargetsFormSubmitRequest } from "../../view_logics/AdvertiserOffers/EditAdvertiserOffer";

import AppStore from "../../config/AppStore";
import MultiSelectField from "../../components/common/MultiSelectField";
import * as Yup from "yup";
import Spinner from "../../components/common/Spinner";
import { format, parse } from "date-fns";
import MultiSelectWithAddRemove from "./MultiSelectWithAddRemove";
import LoadingButton from "../../components/common/LoadingButton";
import { sendRequestGetPublishers } from "../../view_logics/Publishers";
import { isEmptyArray } from "../../../utils/common_functions";

let targetingDevicesMeta = [
  { id: "desktop", label: "Desktop" },
  { id: "ipad", label: "IPad" },
  { id: "iphone", label: "IPhone" },
];

let weekDaysMeta = [
  { id: "sun", label: "Sunday" },
  { id: "mon", label: "Monday" },
  { id: "tue", label: "Tuesday" },
  { id: "wed", label: "Wednesday" },
  { id: "thu", label: "Thursday" },
  { id: "fri", label: "Friday" },
  { id: "sat", label: "Satruday" },
];

let phoneMeta = [
  { id: "cell", label: "Cell" },
  { id: "landline", label: "Landline" },
];

const TargetingTab = (advertiserOfferInfo, setActiveTab) => {
  const [targetingFormLoading, setTargetingFormLoading] = useState(false);
  const selectRefs = useRef([]);

  const [offerUpdatedSuccessfully, setOfferUpdatedSuccessfully] =
    useState(false);
  const store = AppStore.getAppStoreInstance();
  const { id } = useParams();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    // url: '/offers',
    history: navigate,
  };

  const [publishersApiData, setPublishersApiData] = useState([]);
  const [publishersList, setPublishersList] = useState([]);

  const getPublishersInputObj = {
    accessToken: store.getState().login.accessToken,
    url: "",
    dataHander: setPublishersApiData,
  };

  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedZipCodes, setSelectedZipCodes] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedExcludedDomains, setSelectedExcludedDomains] = useState([]);

  const formik = useFormik({
    initialValues: {
      targetDeviceFlag: false,
      targetingDevices: "",
      targetGenderFlag: false,
      targetingGender: "",
      targetPhoneFlag: false,
      targetingPhone: "",
      targetDayFlag: false,
      targetingDays: "",
      targetTimeFlag: false,
      targetingFromTime: "09:03:05",
      targetingToTime: "",
      targetCityFlag: false,
      targetingCities: "",
      targetAgeFlag: false,
      targetFromAge: "",
      targetToAge: "",
      targetExcludeCityFlag: false,
      targetExcludeCityValue: "",
      targetStateFlag: false,
      targetingStates: "",
      targetExcludeStateFlag: false,
      targetExcludeStateValue: "",
      targetZipFlag: false,
      targetingZipCodes: "",
      targetExcludeZipFlag: false,
      targetExcludeZipValue: "",

      targetCountryFlag: false,
      targetingCountries: "",

      targetDomainFlag: false,
      targetingDomains: "",
      targetIncludeDomainFlag: false,
      targetIncludeDomainValue: "",
      targetExcludeDomainFlag: false,
      targetingExcludedDomains: "",

      targetIncludePublisherFlag: false,
      targetingIncludePublishers: "",

      targetExcludePublisherFlag: false,
      targetingExcludedPublishers: "",
    },
  });

  const myadvertiserOfferInfo = advertiserOfferInfo.advertiserOfferInfo;
  // console.log(myadvertiserOfferInfo);
  console.log("🚀 ~ TargetingTab ~ formik:", formik);
  const [targettingInfoObject, setTargettingInfoObject] = useState({
    offerId: myadvertiserOfferInfo.id,
    targetDeviceFlag: myadvertiserOfferInfo.target_device_flag,
    targetingDevices: [],
    targetGenderFlag: myadvertiserOfferInfo.target_gender_flag,
    targetingGender: "",
    targetPhoneFlag: myadvertiserOfferInfo.target_phone_flag,
    targetingPhone: [],
    targetDayFlag: myadvertiserOfferInfo.target_day_flag,
    targetingDays: [],
    targetTimeFlag: myadvertiserOfferInfo.target_time_flag,
    targetingFromTime: "",
    targetingToTime: "",
    targetAgeFlag: myadvertiserOfferInfo.target_age_flag,
    targetFromAge: "",
    targetToAge: "",
    targetCityFlag: myadvertiserOfferInfo.target_city_flag,
    targetingCities: "",
    targetExcludeCityFlag: myadvertiserOfferInfo.target_exclude_city_flag,
    targetExcludeCityValue: "",
    targetStateFlag: myadvertiserOfferInfo.target_state_flag,
    targetingStates: "",
    targetExcludeStateFlag: myadvertiserOfferInfo.target_exclude_state_flag,
    targetExcludeStateValue: "",
    targetZipFlag: myadvertiserOfferInfo.target_zip_flag,
    targetingZipCodes: "",
    targetExcludeZipFlag: myadvertiserOfferInfo.target_exclude_state_flag,
    targetExcludeZipValue: "",
    targetDomainFlag: myadvertiserOfferInfo.target_include_domain_flag,
    targetingDomains: "",
    targetIncludeDomainFlag: myadvertiserOfferInfo.target_include_domain_flag,
    targetIncludeDomainValue: "",
    targetCountryFlag: myadvertiserOfferInfo?.target_country_flag,
    targetingCountries: "",
    targetExcludeDomainFlag: myadvertiserOfferInfo?.target_exclude_domain_flag,
    targetingExcludedDomains: "",
    targetIncludePublisherFlag:
      myadvertiserOfferInfo.target_include_publisher_flag,
    targetingIncludePublishers: [],

    targetExcludePublisherFlag:
      myadvertiserOfferInfo.target_exclude_publisher_flag,
    targetingExcludedPublishers: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sendRequestGetPublishers(getPublishersInputObj);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (publishersApiData?.length > 0) {
      let tempArr = [];
      tempArr = publishersApiData?.map((obj, ind) => {
        let updatedObj = {
          id: obj.id,
          label: obj.first_name + " " + obj?.last_name,
        };
        return updatedObj;
      });
      if (tempArr?.length > 0) {
        setPublishersList(tempArr);
      }
    }
  }, [publishersApiData]);

  const handleCheckboxChange = (fieldName, event) => {
    if (event.target.checked == true) {
      setTargettingInfoObject({
        ...targettingInfoObject,
        [fieldName]: event.target.checked,
      });
    } else {
      setTargettingInfoObject({
        ...targettingInfoObject,
        [fieldName]: event.target.checked,
      });
    }

    // if (event.target.checked) {
    //   if (fieldName == "targetingDevices") {
    //     setTargetingDevicesField(false);
    //     event.target.checked = true;
    //     event.target.value = true;
    //   }
    //   if (fieldName == "targetingDays") {
    //     setTargetingDaysField(false);
    //     event.target.checked = true;
    //     event.target.value = true;
    //   }
    //   if (fieldName == "targetingPhone") {
    //     setTargetingPhoneField(false);
    //     event.target.checked = true;
    //     event.target.value = true;
    //   }

    //   document.getElementById(fieldName).disabled = "";
    //   event.target.checked = true;
    //   event.target.value = true;
    // } else {
    //   if (fieldName == "targetingDevices") {
    //     setTargetingDevicesField(true);
    //     event.target.checked = false;
    //     event.target.value = false;
    //   }
    //   if (fieldName == "targetingDays") {
    //     setTargetingDaysField(true);
    //     event.target.checked = false;
    //     event.target.value = false;
    //   }
    //   if (fieldName == "targetingPhone") {
    //     setTargetingPhoneField(true);
    //     event.target.checked = false;
    //     event.target.value = false;
    //   }
    //   document.getElementById(fieldName).disabled = "disabled";
    //   event.target.checked = false;
    //   event.target.value = false;
    // }
  };

  useEffect(() => {
    if (advertiserOfferInfo?.advertiserOfferInfo) {
      if (advertiserOfferInfo?.advertiserOfferInfo?.target_device_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetDeviceFlag: true,
        });
      }
      if (advertiserOfferInfo?.advertiserOfferInfo?.include_publisher == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          target_include_publisher_flag: true,
        });
      }
      if (
        advertiserOfferInfo?.advertiserOfferInfo
          ?.target_exclude_publisher_flag == 1
      ) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          target_exclude_publisher_flag: true,
        });
      }

      if (advertiserOfferInfo?.advertiserOfferInfo?.target_day_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetDayFlag: true,
        });
      }
      if (advertiserOfferInfo?.advertiserOfferInfo?.target_phone_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetPhoneFlag: true,
        });
      }
      if (advertiserOfferInfo?.advertiserOfferInfo?.target_gender_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetGenderFlag: true,
        });
      }
      if (advertiserOfferInfo?.advertiserOfferInfo?.target_age_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetAgeFlag: true,
        });
      }
      if (advertiserOfferInfo?.advertiserOfferInfo?.target_time_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetTimeFlag: true,
        });
      }

      if (advertiserOfferInfo?.advertiserOfferInfo?.target_city_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetCityFlag: true,
        });
      }
      if (advertiserOfferInfo?.advertiserOfferInfo?.target_state_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetStateFlag: true,
        });
      }
      if (advertiserOfferInfo?.advertiserOfferInfo?.target_zip_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetZipFlag: true,
        });
      }
      if (advertiserOfferInfo?.advertiserOfferInfo?.target_domain_flag == 1) {
        setTargettingInfoObject({
          ...targettingInfoObject,
          targetDomainFlag: true,
        });
      }

      // to set values in fields
      if (advertiserOfferInfo?.advertiserOfferInfo?.offer_targets?.length > 0) {
        let newArr = [];
        let devicesArr = [];
        let phoneArr = [];
        let dayArr = [];
        let publisherArr = [];

        newArr = JSON.parse(
          JSON.stringify(
            advertiserOfferInfo?.advertiserOfferInfo?.offer_targets
          )
        );
        newArr.forEach((ele, ind) => {
          console.log("🚀 ~ newArr.forEach ~ ele:", ele);
          if (ele.target_option.includes("gender")) {
            setTargettingInfoObject((prevConfigObject) => ({
              ...prevConfigObject,
              targetingGender: ele.target_value,
            }));
            // targettingInfoObject.targetingGender = ele.target_value;
          } else if (ele.target_option.includes("phone")) {
            let findPhone = phoneMeta.find(
              (item) => item.id == ele.target_value
            );
            if (findPhone != undefined) {
              phoneArr.push(JSON.parse(JSON.stringify(findPhone)));
              setTargettingInfoObject((prevConfigObject) => ({
                ...prevConfigObject,
                targetingPhone: [...phoneArr],
              }));
            }
          } else if (ele.target_option.includes("device")) {
            let findDevice = targetingDevicesMeta.find(
              (item) => item.id == ele.target_value
            );
            if (findDevice != undefined) {
              devicesArr.push(JSON.parse(JSON.stringify(findDevice)));

              setTargettingInfoObject((prevConfigObject) => ({
                ...prevConfigObject,
                targetingDevices: [...devicesArr],
              }));
            }
          } else if (ele.target_option.includes("day")) {
            let findDay = weekDaysMeta.find(
              (item) => item.id == ele.target_value
            );
            if (findDay != undefined) {
              console.log("🚀 ~ newArr.forEach ~ findDay:", findDay);
              dayArr.push(findDay);

              setTargettingInfoObject((prevConfigObject) => ({
                ...prevConfigObject,
                targetingDays: [...dayArr],
              }));
            }
          } else if (ele.target_option.includes("time")) {
            if (ele.target_start_value != "") {
              let updatedFromTime = ele.target_start_value.replace(
                /[APMapm ]/g,
                ""
              );
              setTargettingInfoObject((prevConfigObject) => ({
                ...prevConfigObject,
                targetingFromTime: updatedFromTime,
              }));
            }
            if (ele.target_end_value != "") {
              let updatedToTime = ele.target_end_value.replace(
                /[APMapm ]/g,
                ""
              );
              setTargettingInfoObject((prevConfigObject) => ({
                ...prevConfigObject,
                targetingToTime: updatedToTime,
              }));
            }
          } else if (ele.target_option.includes("age")) {
            setTargettingInfoObject((prevConfigObject) => ({
              ...prevConfigObject,
              targetFromAge: ele.target_start_value,
              targetToAge: ele.target_end_value,
            }));
          } else if (ele.target_option.includes("city")) {
            setSelectedCities((prev) => {
              if (prev.includes(ele.target_value)) {
                return prev;
              } else {
                return [...prev, ele.target_value];
              }
            });
          } else if (ele.target_option.includes("state")) {
            setSelectedStates((prev) => {
              if (prev.includes(ele.target_value)) {
                return prev;
              } else {
                return [...prev, ele.target_value];
              }
            });
          } else if (ele.target_option.includes("zip")) {
            setSelectedZipCodes((prev) => {
              if (prev.includes(ele.target_value)) {
                return prev;
              } else {
                return [...prev, ele.target_value];
              }
            });
          } else if (ele.target_option.includes("country")) {
            setSelectedCountries((prev) => {
              if (prev.includes(ele.target_value)) {
                return prev;
              } else {
                return [...prev, ele.target_value];
              }
            });
          } else if (ele.target_option == "include_domain") {
            setSelectedDomains((prev) => {
              if (prev.includes(ele.target_value)) {
                return prev;
              } else {
                return [...prev, ele.target_value];
              }
            });
          } else if (ele.target_option.includes("exclude_domain")) {
            setSelectedExcludedDomains((prev) => {
              if (prev.includes(ele.target_value)) {
                return prev;
              } else {
                return [...prev, ele.target_value];
              }
            });
          } else if (ele.target_option.includes("include_publisher")) {
            let tempArr = [];
            if (tempArr.length == 0) {
              tempArr = publishersApiData?.map((obj, ind) => {
                let updatedObj = {
                  id: obj.id,
                  label: obj.first_name + " " + obj?.last_name,
                };
                return updatedObj;
              });
            }
            if (tempArr?.length > 0) {
              let findPublisher = tempArr.find(
                (item) => item.id == ele.target_value
              );
              if (findPublisher != undefined) {
                publisherArr.push(JSON.parse(JSON.stringify(findPublisher)));

                setTargettingInfoObject((prevConfigObject) => ({
                  ...prevConfigObject,
                  targetingIncludePublishers: [...publisherArr],
                }));
              }
            }
          } else if (ele.target_option.includes("exclude_publisher")) {
            let tempArr = [];
            if (tempArr.length == 0) {
              tempArr = publishersApiData?.map((obj, ind) => {
                let updatedObj = {
                  id: obj.id,
                  label: obj.first_name + " " + obj?.last_name,
                };
                return updatedObj;
              });
            }
            if (tempArr?.length > 0) {
              let findPublisher = tempArr.find(
                (item) => item.id == ele.target_value
              );
              if (findPublisher != undefined) {
                publisherArr.push(JSON.parse(JSON.stringify(findPublisher)));

                setTargettingInfoObject((prevConfigObject) => ({
                  ...prevConfigObject,
                  targetingExcludedPublishers: [...publisherArr],
                }));
              }
            }
          } else {
            return;
          }
        });
      }
    }
  }, [advertiserOfferInfo?.advertiserOfferInfo, publishersApiData]);

  const updateTagetingInfo = (event, actions) => {
    console.log("🚀 ~ updateTagetingInfo ~ actions:", actions);
    event.preventDefault();
    const formData = new FormData(event.target);
    var serializedData = {};
    serializedData["targetDeviceFlag"] = 0;
    serializedData["targetGenderFlag"] = 0;
    serializedData["targetAgeFlag"] = 0;
    serializedData["targetPhoneFlag"] = 0;
    serializedData["targetDayFlag"] = 0;
    serializedData["targetTimeFlag"] = 0;
    serializedData["targetCityFlag"] = 0;
    serializedData["targetStateFlag"] = 0;
    serializedData["targetDomainFlag"] = 0;
    serializedData["targetZipFlag"] = 0;
    serializedData["targetCountryFlag"] = 0;
    serializedData["targetExcludeDomainFlag"] = 0;
    serializedData["targetIncludePublisherFlag"] = 0;
    serializedData["targetExcludePublisherFlag"] = 0;
    serializedData["targetingIncludePublishers"] = [];
    serializedData["targetingExcludedPublishers"] = [];

    var targettingDevices = [];
    var targetingDays = [];
    var targetingGender = "";
    var targetFromAge = "";
    var targetToAge = "";
    var targetingPhone = [];
    var targetingDays = [];
    var targetingFromTime = "";
    var targetingToTime = "";
    let targettingPublishers = [];

    for (let [name, value] of formData.entries()) {
      console.log("🚀 ~ updateTagetingInfo ~ name:", name, value);
      if (
        document.getElementById(name) &&
        document.getElementById(name).type === "checkbox"
      ) {
        serializedData[name] = 1;
      } else if (name.includes("targetingDevices")) {
        targettingDevices.push(value);
      } else if (name.includes("targetingIncludePublishers")) {
        targettingPublishers.push(value);
      } else if (name.includes("targetingExcludedPublishers")) {
        targettingPublishers.push(value);
      } else if (name.includes("targetingDays")) {
        targetingDays.push(value);
      } else if (name.includes("targetingGender")) {
        targetingGender = value;
      } else if (name.includes("targetFromAge")) {
        targetFromAge = parseFloat(value);
      } else if (name.includes("targetToAge")) {
        targetToAge = parseFloat(value);
      } else if (name.includes("targetingPhone")) {
        targetingPhone.push(value);
      } else if (name.includes("targetingFromTime")) {
        let [h, m] = document
          .getElementById("targetingFromTime")
          .value.split(":");
        let amPm = h >= 12 ? "PM" : "AM";
        let time24 = value;
        const time12 = convertTo12HourFormat(time24);
        targetingFromTime = time12;
      } else if (name.includes("targetingToTime")) {
        let [h, m] = document
          .getElementById("targetingToTime")
          .value.split(":");
        let amPm = h >= 12 ? "PM" : "AM";
        let time24 = value;
        const time12 = convertTo12HourFormat(time24);
        targetingToTime = time12;
      } else {
        serializedData[name] = value;
      }
    }
    delete serializedData["_token"];
    delete serializedData["_token"];
    serializedData["targetingDevices"] =
      targettingDevices.length > 0 ? targettingDevices : [];
    serializedData["targetingDays"] = targetingDays;
    serializedData["targetingGender"] = targetingGender;
    serializedData["targetFromAge"] = targetFromAge;
    serializedData["targetToAge"] = targetToAge;
    serializedData["targetingPhone"] = targetingPhone;
    serializedData["targetingDays"] = targetingDays;
    serializedData["targetingFromTime"] = targetingFromTime;
    serializedData["targetingToTime"] = targetingToTime;
    serializedData["targetingCities"] = selectedCities;
    serializedData["targetingStates"] = selectedStates;
    serializedData["targetingDomains"] = selectedDomains;
    serializedData["targetingZipCodes"] = selectedZipCodes;
    serializedData["targetingCountries"] = selectedCountries;
    serializedData["targetingExcludedDomains"] = selectedExcludedDomains;
    serializedData["targetingPublishers"] = targettingPublishers;
    console.log("🚀 ~ updateTagetingInfo ~ serializedData:", serializedData);

    if (
      serializedData.targetDeviceFlag == 1 &&
      isEmptyArray(serializedData.targetingDevices)
    ) {
      if (selectRefs.current[0]) {
        return selectRefs.current[0].focus();
      }
    }

    if (
      serializedData.targetPhoneFlag == 1 &&
      isEmptyArray(serializedData.targetingPhone)
    ) {
      if (selectRefs.current[1]) {
        return selectRefs.current[1].focus();
      }
    }

    if (
      serializedData.targetDayFlag == 1 &&
      isEmptyArray(serializedData.targetingDays)
    ) {
      if (selectRefs.current[2]) {
        return selectRefs.current[2].focus();
      }
    }

    if (
      serializedData.targetExcludeDomainFlag == 1 &&
      serializedData.targetingExcludedDomains.length == 0
    ) {
      let ele = document.getElementById("targetingExcludedDomains");
      if (ele) {
        return ele.focus();
      }
    }

    if (
      serializedData.targetDomainFlag == 1 &&
      serializedData.targetingDomains.length == 0
    ) {
      let ele = document.getElementById("targetingDomains");
      if (ele) {
        return ele.focus();
      }
    }

    if (
      serializedData.targetCountryFlag == 1 &&
      serializedData.targetingCountries.length == 0
    ) {
      let ele = document.getElementById("targetingCountries");
      if (ele) {
        return ele.focus();
      }
    }

    if (
      serializedData.targetZipFlag == 1 &&
      serializedData.targetingZipCodes.length == 0
    ) {
      let ele = document.getElementById("targetingZipCodes");
      if (ele) {
        return ele.focus();
      }
    }

    if (
      serializedData.targetCityFlag == 1 &&
      serializedData.targetingCities.length == 0
    ) {
      let ele = document.getElementById("targetingCities");
      if (ele) {
        return ele.focus();
      }
    }

    if (
      serializedData.targetStateFlag == 1 &&
      serializedData.targetingStates.length == 0
    ) {
      let ele = document.getElementById("targetingStates");
      if (ele) {
        return ele.focus();
      }
    }

    if (serializedData.targetIncludePublisherFlag == 1) {
      serializedData["targetingIncludePublishers"] =
        serializedData.targetingPublishers;
    }
    if (serializedData.targetExcludePublisherFlag == 1) {
      serializedData["targetingExcludedPublishers"] =
        serializedData.targetingPublishers;
    }

    if (
      serializedData.targetIncludePublisherFlag == 1 &&
      isEmptyArray(serializedData.targetingIncludePublishers)
    ) {
      if (selectRefs.current[3]) {
        return selectRefs.current[3].focus();
      }
    }

    if (
      serializedData.targetExcludePublisherFlag == 1 &&
      isEmptyArray(serializedData.targetingExcludedPublishers)
    ) {
      if (selectRefs.current[4]) {
        return selectRefs.current[4].focus();
      }
    }

    // delete serializedData.targetingPublishers
    console.log("serialixed data ss", serializedData);
    delete serializedData["autoDisableAtFlag"];
    const inputObj = {
      accessToken: store.getState().login.accessToken,
      url: "/offers",
      history: navigate,
      dataToPost: serializedData,
      errorStateChangeHandler: setOfferUpdatedSuccessfully,
      setActiveTab: setActiveTab,
      loadingHandler: setTargetingFormLoading,
    };
    setTargetingFormLoading(true);
    sendUpdateAdvertiserOfferTargetsFormSubmitRequest(inputObj);
  };

  function convertTo12HourFormat(time24) {
    // Parse the 24-hour time string
    const [hours, minutes, sec] = time24.split(":");
    let hh = parseInt(hours, 10);
    const mm = minutes || "00";
    const ss = sec || "00";
    // Determine AM/PM
    const period = hh >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hh = hh % 12 || 12;

    // Return the formatted time
    return `${String(hh).padStart(2, "0")}:${mm}:${ss} ${period}`;
  }

  function isValidTime(value) {
    if (!value) return true; // Allow empty value

    const regex = /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/;
    let result = regex.test(value);
    return result;
  }

  const targettingValidation = Yup.object().shape({
    targetingFromTime: Yup.string().test(
      "isValidFromTime",
      "Invalid time format",
      function (value) {
        return isValidTime(value);
      }
    ),
    targetingToTime: Yup.string()
      .test(
        "isValidToTime",
        "To time cannot be greater than from time.",
        function (value) {
          let [h, m] = document
            .getElementById("targetingFromTime")
            .value.split(":");
          let time1 = h + ":" + m;
          let [th, tm] = document
            .getElementById("targetingToTime")
            .value.split(":");
          let time2 = th + ":" + tm;

          let time1InMinutesForTime1 = getTimeAsNumberOfMinutes(time1);
          let time1InMinutesForTime2 = getTimeAsNumberOfMinutes(time2);

          let time1IsBeforeTime2 =
            time1InMinutesForTime1 < time1InMinutesForTime2;

          function getTimeAsNumberOfMinutes(time) {
            let timeParts = time.split(":");

            let timeInMinutes = timeParts[0] * 60 + timeParts[1];

            return timeInMinutes;
          }

          if (time1IsBeforeTime2) {
            return true;
          } else {
            return false;
          }
        }
      )
      .test("isValidTime", "Invalid time format", function (value) {
        return isValidTime(value);
      }),

    targetFromAge: Yup.number()
      .integer("Please enter a valid integer for from age")
      .min(0, "From age must be greater than or equal to 0")
      .max(
        Yup.ref("targetToAge"),
        "From age must be less than or equal to to age"
      )
      .required("From age is required"),
    targetToAge: Yup.number()
      .integer("Please enter a valid integer for to age")
      .min(
        Yup.ref("targetFromAge"),
        "To age must be greater than or equal to from age"
      )
      .required("To age is required"),
  });

  console.log("targettingInfoObject", targettingInfoObject);
  return (
    <div className="position-relative">
      <div className="tab-pane active" id="tab-1" role="tabpanel">
        <Formik
          initialValues={targettingInfoObject || formik.initialValues}
          validationSchema={targettingValidation}
          // onSubmit={updateTagetingInfo}
          enableReinitialize
        >
          {({ handleSubmit, errors, isValid, values, setFieldValue }) => {
            console.log("values of targeting", values);
            let shouldValid = true;
            shouldValid = !targettingInfoObject?.offerId;
            console.log(
              "isValid >>>",
              isValid,
              shouldValid,
              errors,
              advertiserOfferInfo?.id
            );
            return (
              <form onSubmit={updateTagetingInfo}>
                <input
                  type="hidden"
                  name="_token"
                  value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf"
                />
                <input
                  type="hidden"
                  name="offerId"
                  value={targettingInfoObject.offerId}
                />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Select Filters</h5>
                    {!targettingInfoObject.offerId ? (
                      <div
                        className="alert alert-danger alert-outline alert-dismissible"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                        <div className="alert-icon">
                          <i className="far fa-fw fa-bell"></i>
                        </div>
                        <div className="alert-message">
                          <strong>Warning!</strong> Please fill out and save the
                          basic information first!
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Devices</label>
                        <div className=" input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              name="targetDeviceFlag"
                              id="targetDeviceFlag"
                              className="form-check-input"
                              /* onClick={(event) =>
                                handleCheckboxChange("targetDeviceFlag", event)
                              } */
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                setFieldValue("targetDeviceFlag", isChecked);
                                if (!isChecked) {
                                  setFieldValue("targetingDevices", '');
                                }
                              }}
                              disabled={shouldValid}
                            ></Field>
                          </div>
                          <MultiSelectField
                            name="targetingDevices"
                            options={targetingDevicesMeta}
                            isMulti={true}
                            disabled={!values?.targetDeviceFlag}
                            ref={(ref) => (selectRefs.current[0] = ref)}
                            values={values}
                          />

                          <ErrorMessage
                            name="targetingDevices"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Gender</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetGenderFlag"
                              name="targetGenderFlag"
                              className="form-check-input"
                              /* onClick={(event) =>
                                handleCheckboxChange("targetGenderFlag", event)
                              } */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetGenderFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetingGender", '');
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                          <Field
                            as="select"
                            name="targetingGender"
                            id="targetingGender"
                            className="form-control"
                            disabled={!values?.targetGenderFlag}
                            required
                          >
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Phone</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetPhoneFlag"
                              name="targetPhoneFlag"
                              className="form-check-input"
                              /* onClick={(event) =>
                                handleCheckboxChange("targetPhoneFlag", event)
                              } */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetPhoneFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetingPhone", '');
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                          <MultiSelectField
                            name="targetingPhone"
                            options={phoneMeta}
                            isMulti={true}
                            disabled={!values?.targetPhoneFlag}
                            ref={(ref) => (selectRefs.current[1] = ref)}
                            values={values}
                          />
                        </div>
                      </div>
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Day</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetDayFlag"
                              name="targetDayFlag"
                              className="form-check-input"
                              /* onClick={(event) =>
                                handleCheckboxChange("targetDayFlag", event)
                              } */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetDayFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetingDays", '');
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                          <MultiSelectField
                            name="targetingDays"
                            options={weekDaysMeta}
                            isMulti={true}
                            disabled={!values?.targetDayFlag}
                            ref={(ref) => (selectRefs.current[2] = ref)}
                            values={values}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">From Time</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetTimeFlag"
                              name="targetTimeFlag"
                              className="form-check-input"
                              /* onClick={(event) => {
                                handleCheckboxChange("targetTimeFlag", event);
                                // handleCheckboxChange("targetingToTime", event);
                              }} */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetTimeFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetingFromTime", '');
                                    setFieldValue("targetingToTime", '');
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                          <Field
                            type="time"
                            name="targetingFromTime"
                            id="targetingFromTime"
                            className="form-control"
                            //step="60"
                            min="00:00"                    // Minimum time at 00:00 (12 AM in 24-hour format)
  max="23:59"
                            //pattern="(0[1-9]|1[0-2]):[0-5][0-9]"
                            disabled={!values?.targetTimeFlag}
                            required
                          ></Field>
                          <ErrorMessage
                            name="targetingFromTime"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                      <div className="mb-0 col-md-6">
                        <label className="form-label">To Time</label>
                        <div className="input-group">
                          <Field
                            type="time"
                            name="targetingToTime"
                            id="targetingToTime"
                            className="form-control"
                            step="60"
                            disabled={!values?.targetTimeFlag}
                            required
                          ></Field>
                          <ErrorMessage
                            name="targetingToTime"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">From Age</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetAgeFlag"
                              name="targetAgeFlag"
                              className="form-check-input"
                              /* onClick={(event) => {
                                handleCheckboxChange("targetAgeFlag", event);
                              }} */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetAgeFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetFromAge", '');
                                    setFieldValue("targetToAge", '');
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                          <Field
                            type="number"
                            name="targetFromAge"
                            id="targetFromAge"
                            className="form-control"
                            disabled={!values?.targetAgeFlag}
                            required
                          ></Field>
                          <ErrorMessage
                            name="targetFromAge"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                      <div className="mb-0 col-md-6">
                        <label className="form-label">To Age</label>
                        <div className="input-group">
                          <Field
                            type="number"
                            name="targetToAge"
                            id="targetToAge"
                            className="form-control"
                            disabled={!values?.targetAgeFlag}
                            required
                          ></Field>
                          <ErrorMessage
                            name="targetToAge"
                            component="div"
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Include City</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetCityFlag"
                              name="targetCityFlag"
                              className="form-check-input"
                              /* onClick={(event) =>
                                handleCheckboxChange("targetCityFlag", event)
                              } */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetCityFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetingCities", '');
                                    setSelectedCities([]);
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                        </div>
                        {values?.targetCityFlag == 1 && (
                          <div>
                            <MultiSelectWithAddRemove
                              name="targetingCities"
                              placeholder="Enter city name"
                              selectedValues={selectedCities}
                              setselectedValues={setSelectedCities}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Include State</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetStateFlag"
                              name="targetStateFlag"
                              className="form-check-input"
                              /* onClick={(event) =>
                                handleCheckboxChange("targetStateFlag", event)
                              } */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetStateFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetingStates", '');
                                    setSelectedStates([]);
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                        </div>
                        {values?.targetStateFlag == 1 && (
                          <div>
                            <MultiSelectWithAddRemove
                              name="targetingStates"
                              placeholder="Enter state name"
                              selectedValues={selectedStates}
                              setselectedValues={setSelectedStates}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Include Zip</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetZipFlag"
                              name="targetZipFlag"
                              className="form-check-input"
                              /* onClick={(event) =>
                                handleCheckboxChange("targetZipFlag", event)
                              } */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetZipFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetingZipCodes", '');
                                    setSelectedZipCodes([]);
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                        </div>
                        {values?.targetZipFlag == 1 && (
                          <div>
                            <MultiSelectWithAddRemove
                              name="targetingZipCodes"
                              placeholder="Enter zip code"
                              selectedValues={selectedZipCodes}
                              setselectedValues={setSelectedZipCodes}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-0 col-md-6">
                        <label className="form-label">Include Country</label>
                        <div className="input-group">
                          <div className="input-group-text">
                            <Field
                              type="checkbox"
                              id="targetCountryFlag"
                              name="targetCountryFlag"
                              className="form-check-input"
                              /* onClick={(event) =>
                                handleCheckboxChange("targetCountryFlag", event)
                              } */
                                onChange={(event) => {
                                  const isChecked = event.target.checked;
                                  setFieldValue("targetCountryFlag", isChecked);
                                  if (!isChecked) {
                                    setFieldValue("targetingCountries", '');
                                    setSelectedCountries([]);
                                  }
                                }}
                              disabled={shouldValid}
                            />
                          </div>
                        </div>
                        {values?.targetCountryFlag == 1 && (
                          <div>
                            <MultiSelectWithAddRemove
                              name="targetingCountries"
                              placeholder="Enter country name"
                              selectedValues={selectedCountries}
                              setselectedValues={setSelectedCountries}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {values?.targetExcludeDomainFlag != 1 && (
                    <div className="card-body">
                      <div className="row">
                        <div className="mb-0 col-md-6">
                          <label className="form-label">Include Domains</label>
                          <div className="input-group">
                            <div className="input-group-text">
                              <Field
                                type="checkbox"
                                id="targetDomainFlag"
                                name="targetDomainFlag"
                                className="form-check-input"
                                /* onClick={(event) =>
                                  handleCheckboxChange(
                                    "targetDomainFlag",
                                    event
                                  )
                                } */
                                  onChange={(event) => {
                                    const isChecked = event.target.checked;
                                    setFieldValue("targetDomainFlag", isChecked);
                                    if (!isChecked) {
                                      setFieldValue("targetingDomains", '');
                                      setSelectedDomains([]);
                                    }
                                  }}
                                disabled={shouldValid}
                              />
                            </div>
                          </div>
                          {values?.targetDomainFlag == 1 && (
                            <div>
                              <MultiSelectWithAddRemove
                                name="targetingDomains"
                                placeholder="Enter domain name"
                                selectedValues={selectedDomains}
                                setselectedValues={setSelectedDomains}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {values?.targetDomainFlag != 1 && (
                    <div className="card-body">
                      <div className="row">
                        <div className="mb-0 col-md-6">
                          <label className="form-label">Exclude Domains</label>
                          <div className="input-group">
                            <div className="input-group-text">
                              <Field
                                type="checkbox"
                                id="targetExcludeDomainFlag"
                                name="targetExcludeDomainFlag"
                                className="form-check-input"
                                /* onClick={(event) =>
                                  handleCheckboxChange(
                                    "targetExcludeDomainFlag",
                                    event
                                  )
                                } */
                                  onChange={(event) => {
                                    const isChecked = event.target.checked;
                                    setFieldValue("targetExcludeDomainFlag", isChecked);
                                    if (!isChecked) {
                                      setFieldValue("targetingExcludedDomains", '');
                                      setSelectedExcludedDomains([]);
                                    }
                                  }}
                                disabled={shouldValid}
                              />
                            </div>
                          </div>
                          {values?.targetExcludeDomainFlag == 1 && (
                            <div>
                              <MultiSelectWithAddRemove
                                name="targetingExcludedDomains"
                                placeholder="Enter domain name"
                                selectedValues={selectedExcludedDomains}
                                setselectedValues={setSelectedExcludedDomains}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="card-body">
                    <div className="d-flex flex-column gap-3">
                      {values?.targetExcludePublisherFlag != 1 && (
                        <div className="mb-0 col-md-6">
                          <label className="form-label">
                            Include Publishers
                          </label>
                          <div className=" input-group">
                            <div className="input-group-text">
                              <Field
                                type="checkbox"
                                name="targetIncludePublisherFlag"
                                id="targetIncludePublisherFlag"
                                className="form-check-input"
                                /* onClick={(event) =>
                                  handleCheckboxChange(
                                    "targetIncludePublisherFlag",
                                    event
                                  )
                                } */
                                  onChange={(event) => {
                                    const isChecked = event.target.checked;
                                    setFieldValue("targetIncludePublisherFlag", isChecked);
                                    if (!isChecked) {
                                      setFieldValue("targetingIncludePublishers", '');
                                    }
                                  }}
                                disabled={shouldValid}
                              ></Field>
                            </div>

                            <MultiSelectField
                              name="targetingIncludePublishers"
                              options={publishersList}
                              isMulti={true}
                              disabled={!values?.targetIncludePublisherFlag}
                              ref={(ref) => (selectRefs.current[3] = ref)}
                              values={values}
                            />

                            <ErrorMessage
                              name="targetingIncludePublishers"
                              component="div"
                            />
                          </div>
                        </div>
                      )}
                      {values?.targetIncludePublisherFlag != 1 && (
                        <div className="mb-0 col-md-6">
                          <label className="form-label">
                            Exclude Publishers
                          </label>
                          <div className=" input-group">
                            <div className="input-group-text">
                              <Field
                                type="checkbox"
                                name="targetExcludePublisherFlag"
                                id="targetExcludePublisherFlag"
                                className="form-check-input"
                               /*  onClick={(event) =>
                                  handleCheckboxChange(
                                    "targetExcludePublisherFlag",
                                    event
                                  )
                                } */
                                  onChange={(event) => {
                                    const isChecked = event.target.checked;
                                    setFieldValue("targetExcludePublisherFlag", isChecked);
                                    if (!isChecked) {
                                      setFieldValue("targetingExcludedPublishers", '');
                                    }
                                  }}
                                disabled={shouldValid}
                              ></Field>
                            </div>

                            <MultiSelectField
                              name="targetingExcludedPublishers"
                              options={publishersList}
                              isMulti={true}
                              disabled={!values?.targetExcludePublisherFlag}
                              ref={(ref) => (selectRefs.current[4] = ref)}
                              values={values}
                            />

                            <ErrorMessage
                              name="targetingExcludedPublishers"
                              component="div"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr></hr>
                  <div className="form-group row mb-0">
                    <div className="d-flex justify-content-end pe-4 pb-3">
                      {targetingFormLoading ? (
                        <LoadingButton name="Save" />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={shouldValid}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      {id && !advertiserOfferInfo?.advertiserOfferInfo?.id && <Spinner />}
    </div>
  );
};

export default TargetingTab;
